<template>
    <div class="height1">
        <!--      头部      -->
        <bread></bread>
        <!--    内容    -->
        <div class="center-content">
            <div class="iq-card-body">
                <div class="table-top">
                    <div class="iq-search-bar">
                        <el-form :inline="true">
                            <el-form-item>
                                <el-date-picker v-model="searchForm.datepicker" type="daterange" range-separator="至"
                                    start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd"
                                    :picker-options="pickerOptions0" size="small" style="margin-right: 10px" @change="search">
                                </el-date-picker>
                            </el-form-item>
                            <el-form-item>
                                <el-input v-model="searchForm.keyWord" type="text" size="small" placeholder="姓名/账号"
                                    @keyup.enter.native="search" clearable ></el-input>
                            </el-form-item>
                            <el-form-item>
                                <el-button type="primary" size="small" @click="search()">搜索</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
                <el-table :data="tableData" stripe height="calc(100% - 90px)" style="width: 100%;" :header-cell-style="{background:'#F5F7FC'}">
                    <el-table-column type="index" label="编号"> </el-table-column>
                    <el-table-column prop="user.userName" label="姓名">
                    </el-table-column>
                    <el-table-column prop="user.phone" label="账号" align="center">
                    </el-table-column>
                    <el-table-column prop="user.vipGrade" label="会员等级" align="center">
                        <template slot-scope="scope">
                            <el-tag size="small" v-if="scope.row.user.vipGrade==0">普通会员</el-tag>
                            <el-tag size="small" v-if="scope.row.user.vipGrade==1" type="warning">VIP1</el-tag>
                            <el-tag size="small" v-if="scope.row.user.vipGrade==2"  type="success">VIP2</el-tag>
                        </template>
                    </el-table-column>
                    <el-table-column prop="payCount" label="本店消费单数" align="center">
                    </el-table-column>
                    <el-table-column prop="addTime" label="添加时间" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.addTime | timefilters }}</p>
                        </template>
                    </el-table-column>
                    <el-table-column prop="lastPayTime" label="最后一次支付时间" align="center">
                        <template slot-scope="scope">
                            <p>{{ scope.row.lastPayTime | timefilters }}</p>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="ChangePage" :page-size="PageSize" :current-page="PageIndex"
                    :total="DataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import bread from "../../../components/common/breadcrumb"
import { Distributor } from "../../../components/HospitalDomain/Distributor";
export default {
    name: "SubStaff",
    components: {
        bread
    },
    data() {
        var distributor = new Distributor(this.TokenClient, this.Services.Authorization);
        return {
            DistributorDomain: distributor,
            tableData: [],
            userId: JSON.parse(sessionStorage.getItem('userInfo')).id, //列表页id
            pickerOptions0: {
                disabledDate(time) {
                    return time.getTime() > Date.now();
                }
            },
            searchForm: {
                statrTime: '',
                endTime: '',
                keyWord: '',
                datepicker: ''
            },
            PageIndex: 1,
            PageTotal: 0,
            PageSize: 15,
            DataTotal: 0,
            dialogFormVisible: false,
            form: {

            },
            formLabelWidth: '120px',
        }
    },
    mounted() {
        this.getList();
    },
    methods: {
        ChangePage(pageIndex) {
            this.PageIndex = pageIndex;
            this.getList();
        },
        search() {
            this.PageIndex = 1;
            this.getList()
        },
        getList() {
            var _this = this;
            var item = _this.searchForm;
            if (_this.searchForm.datepicker) {
                item.startTime = _this.searchForm.datepicker[0] + ' 00:00:00';
                item.endTime = _this.searchForm.datepicker[1] + ' 23:59:59';
            } else {
                item.startTime = "";
                item.endTime = "";
            }
            _this.DistributorDomain.GetOrgPrivateTraffic(item.keyWord, item.startTime, item.endTime, _this.PageIndex,
                function (data) {
                    _this.tableData = data.data.results;
                    _this.PageIndex = data.data.pageIndex;
                    _this.PageSize = data.data.pageSize;
                    _this.DataTotal = data.data.dataTotal;
                },
                function (error) {
                    console.log(error);
                });
        },
    }
}
</script>

<style scoped>
</style>

